// src/utils/axiosInstance.js
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import Cookies from "js-cookie";

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`, // Replace with your API base URL
    withCredentials: true, // Ensure cookies are sent with requests
});


export const isTokenExpired = (token) => {
    if (!token) return true;

    const {exp} = jwtDecode(token);
    if (!exp) return true;

    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    return exp < currentTime;
};

export const getAccessToken = () => {
    const cookies = document.cookie.split('; ');
    const accessTokenCookie = cookies.find(cookie => cookie.startsWith('accessToken='));
    return accessTokenCookie ? accessTokenCookie.split('=')[1] : null;
};

export const getRefreshToken = () => {
    const cookies = document.cookie.split('; ');
    const refreshTokenCookie = cookies.find(cookie => cookie.startsWith('refreshToken='));
    return refreshTokenCookie ? refreshTokenCookie.split('=')[1] : null;
};

axiosInstance.interceptors.request.use(
    async (config) => {
        let accessToken = Cookies.get('access_token');

        if (isTokenExpired(accessToken)) {
            const refreshToken = Cookies.get('refresh_token');

            if (isTokenExpired(refreshToken)) {
                window.location.href = '/login'; // Redirect to login if refresh token is also expired
                return Promise.reject('Session expired. Redirecting to login.');
            }

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/auth/refresh`, // Your refresh token endpoint
                    {},
                    {withCredentials: true}
                );
                accessToken = Cookies.get('access_token');
                // Retry the original request with the new access token
                config.headers.Authorization = `Bearer ${accessToken}`;
                if (response.status !== 200) {
                    console.error('Failed to refresh token');
                    window.location.href = '/auth'; // Redirect to login if token refresh fails
                    return Promise.reject("Failed to refresh token, received unexpected response form the backend");
                }
            } catch (refreshError) {
                console.error('Failed to refresh token', refreshError);
                window.location.href = '/auth'; // Redirect to login if token refresh fails
                return Promise.reject(refreshError);
            }
        } else {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
