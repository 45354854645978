import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";

export default function isUserAuthz() {
    const accessToken = Cookies.get('access_token');
    if (accessToken && !isTokenExpired(accessToken)) { // access token is fresh, user can enjoy the app
        return true
    }
    const refreshToken = Cookies.get('refresh_token');
    if (!refreshToken || isTokenExpired(refreshToken)) { // refresh token is done
        return false
    }
    return true
    // NOTE: Now I allow to user to use client side if his access_token is expired, but refresh isn't
    // Because he still won't have access to the backend in this case
    // And if refresh token is valid, access token should get updated with the call to the backend.
    // Otherwise, with the first call to the backend user will be redirected to the auth page
}

function isTokenExpired(token) {
    if (!token) return true;
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
}

