import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import React from "react";

export default function MainPage() {

    return (
        <>
            <div className="isolate bg-white px-6 lg:px-8">
                <Header></Header>
                <Body></Body>
                <Footer></Footer>
            </div>
        </>
    );
}

